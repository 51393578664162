<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-md-2">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>

      <div class="table-responsive mb-0">
        <b-table
          :items="loans"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          ref="selectableTable"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!-- <template #head(id)>
            <input v-model="isSelectAll" type="checkbox" />
          </template>
          <template v-slot:cell(id)="loans">
            <input
              type="checkbox"
              :value="loans.item.id"
              v-model="checkedNames"
            />
          </template> -->

          <template v-slot:cell(name)="loans">
            <b-img
              style="height: 70px;width: 72px;border-radius: 50%;"
              thumbnail
              fluid
              :src="loans.item.name"
              alt="Image 1"
            ></b-img>
          </template>

          <template v-slot:cell(actions)="loans">
            <i
              style="color:red;cursor: pointer;"
              class="fas fa-trash-alt"
              @click="deleterRecord(loans)"
            ></i>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loans: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [],
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        // { key: "id", label: "Select", sortable: true },

        { key: "name", label: "Image", sortable: true },

        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {},

  computed: {
    rows() {
      return this.loans.length;
    },
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleterRecord(object) {
      this.$emit("deleteData", object, "delete-menu-item-image");
    },
  },

  // watch: {
  //   isSelectAll: function(val) {
  //     if (val) {
  //       this.checkedNames = this.$refs.selectableTable.paginatedItems.map(
  //         (x) => x.id
  //       );
  //     } else {
  //       this.checkedNames = [];
  //     }
  //   },
  // },
};
</script>
